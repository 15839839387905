.timeline-section {
  background: #eee;
  min-height: 100vh;
  padding: 100px 15px;
}
.timeline-heading h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 50px;
}

@media screen and (max-width: 480px) {
  .timeline-heading h1 {
    font-size: 2rem;
  }
}
.timeline-section .timeline-items {
  max-width: 1000px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.timeline-items::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #2f363e;
  left: calc(50% - 1px);
}

.timeline-item {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 30px);
  text-align: center;
}

.timeline-item:nth-child(even) {
  padding-left: calc(50% + 30px);
}

.timeline-dot {
  height: 16px;
  width: 16px;
  background-color: #bd4b4b;
  position: absolute;
  left: calc(50% - 8px);
  border-radius: 50%;
  top: 10px;
}

.timeline-date {
  font-size: 18px;
  color: #bd4b4b;
  margin: 6px 0 15px;
  text-align: center;
}

.timeline-content {
  background-color: #2f363e;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
}
.timeline-content h3 {
  font-size: 20px;
  color: #fff;
  margin: 0 0 10px;
  text-transform: capitalize;
  font-weight: 500;
}
.timeline-content p {
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

/* responsive */
@media (max-width: 767px) {
  .timeline-items::before {
    left: 7px;
  }
  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 37px;
  }
  .timeline-dot {
    left: 0;
  }
}
