footer {
  display: flex;
  justify-content: center;
  height: 750px;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  background: #17181c;
  border-top: 1px solid #232a34;
}
@media screen and (max-width: 568px) {
  footer {
    height: auto;
  }
}
footer .video {
  height: 100%;
}
footer video {
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}
/* .footer-logo { */
/* margin-right: 150px; */
/* } */
.footer-logo img {
  width: 120px;
}
.footer-logo:hover {
  animation: 1s infinite alternate pulse;
  transition: 1s ease-out;
}
@keyframes pulse {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}

.left-container {
  width: 45%;
  height: 100%;
}
.right-container {
  width: 55%;
  height: 100%;
  padding: 8rem;
  /* padding-left: 50px; */
}

.right-container .about ul {
  list-style-type: none;
}
.right-container .about ul li {
  margin-bottom: 20px;
  position: relative;
}
.right-container .about ul a {
  color: #eee;
  text-decoration: none;
  font-size: 32px;
}

.right-container .about ul li a:before {
  content: "";
  position: absolute;
  left: -30px;
  top: 13px;
  width: 6px;
  height: 4px; /* as the half of the width */
  background-color: #eee;
  transform: rotate(90deg);
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid;
  border-bottom: 0;
  opacity: 0;
}

.right-container .about ul li:hover a:before {
  opacity: 1;
  transition: 0.6s;
}
.right-container .about ul li:hover a {
  margin-left: 10px;
  transition: 0.6s;
}

.web-detail-container1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  position: relative;
}
.social ul {
  display: flex;
  list-style-type: none;
}
.social ul li {
  margin-right: 50px;
  position: relative;
  font-size: 32px;
  z-index: 2;
}
.social ul li a {
  color: #eee;
}
.social ul li:last-child {
  margin-right: 0;
}
.social ul li:before {
  content: "";
  border: 2px solid;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  width: 30px;
  height: 30px;
  background-color: #bd4b4b;
  opacity: 0;
  outline: none;
  border: none;
}
.social ul li::before {
  top: -8px;
  left: -8px;
}

.social ul li:hover::before {
  opacity: 1;
  transition: 0.3s;
}
.social ul li a:hover {
  color: #ffffff;
}

.wd-container2 .about {
  width: 50%;
}
.wd-container2 .description {
  width: 50%;
}
.wd-container2 p {
  margin: 20px;
}
.blog a {
  text-decoration: none;
}
.gallery,
.blog {
  font-size: 16px;
  letter-spacing: 1.1px;
  line-height: 28px;
}
.gallery span,
.blog a span {
  color: #bd4b4b;
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: #eee;
}
.gallery span:hover,
.blog a span:hover {
  position: relative;
  bottom: 1.6px;
  transition: 0.6s;
}

.copyright {
  text-align: center;
  color: #eee;
}
.blog,
.gallery {
  text-align: right;
  transition: 0.6s;
  color: #eee;
}
.wd-container2 p {
  margin-right: 0;
}
hr {
  display: none;
}
@media screen and (max-width: 1400px) {
  .right-container {
    padding: 6rem;
    transition: 0.6s;
  }
  .footer-logo img {
    width: 100px;
    transition: 0.6s;
  }
  .social ul li,
  .right-container .about ul a {
    font-size: 28px;
    transition: 0.6s;
  }
}

@media screen and (max-width: 1165px) {
  .right-container {
    padding: 6rem 4rem;
    transition: 0.6s;
  }
  .social ul li {
    margin-right: 30px;
    transition: 0.6s;
  }
}

@media screen and (max-width: 800px) {
  .left-container {
    display: none;
    transition: 0.6s;
  }
  .right-container {
    width: 100%;
    transition: 0.6s;
  }
  .blog,
  .gallery {
    text-align: right;
    transition: 0.6s;
  }
  .wd-container2 p {
    margin: 20px 0 20px 20px;
    transition: 0.6s;
  }
  hr {
    display: block;
    margin: 20px 0;
    transition: 0.6s;
  }
}

@media screen and (max-width: 486px) {
  .web-detail-container1 {
    flex-direction: column;
    margin-bottom: 50px;
    transition: 0.6s;
  }
  .footer-logo {
    margin-bottom: 50px;
    transition: 0.6s;
  }
  .wd-container2 .about {
    text-align: center;
    transition: 0.6s;
  }
  .wd-container2 p {
    text-align: center;
    transition: 0.6s;
  }
  .wd-container2 p {
    margin: 0;
    margin-bottom: 20px;
    transition: 0.6s;
  }
}
