.resume-container {
  background-color: #eee;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.resume {
  margin-top: 80px;
  margin-bottom: 100px;
}

embed {
  width: 100%;
  height: 800px;
  margin-top: 80px;
}
