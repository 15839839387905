.ityped-cursor {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.qualification {
  height: 30px;
}

.my-name {
  color: #f4f9f9;
  font-size: 64px;
  text-align: center;
  margin-bottom: 24px;
  /* animation: slideIn 1s; */
}
@media screen and (max-width: 768px) {
  .my-name {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .my-name {
    font-size: 40px;
  }
}

/* @keyframes slideIn {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0);
  }
} */

.react-reveal {
  z-index: 999;
}

#hero {
  height: 100vh;
}
