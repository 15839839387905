@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

#loading {
  width: 100%;
  z-index: 9999;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #06202a;
}

.load-container {
  width: 112px;
  height: 112px;
}
.load-container .load-box1,
.load-container .load-box2,
.load-container .load-box3 {
  border: 16px solid #f5f5f5;
  box-sizing: border-box;
  position: absolute;
  display: block;
}
.load-container .load-box1 {
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0px;
  -webkit-animation: anime1 4s 0s forwards ease-in-out infinite;
  animation: anime1 4s 0s forwards ease-in-out infinite;
}
.load-container .load-box2 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-animation: anime2 4s 0s forwards ease-in-out infinite;
  animation: anime2 4s 0s forwards ease-in-out infinite;
}
.load-container .load-box3 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  -webkit-animation: anime3 4s 0s forwards ease-in-out infinite;
  animation: anime3 4s 0s forwards ease-in-out infinite;
}
@-webkit-keyframes anime1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}
@keyframes anime1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}
@-webkit-keyframes anime2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}
@keyframes anime2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}
@-webkit-keyframes anime3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}
@keyframes anime3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  background-color: #17181c;
}

a {
  text-decoration: none;
}

/*Container*/
.container {
  font-family: "Oswald", sans-serif;
  width: 100%;
  padding: 15px 0;
}

/*Navbar*/
.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999999;
  height: 3rem;
}

.menu {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 4rem;
  z-index: 999999;
}

.hamburger-menu {
  height: 4rem;
  width: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.active .hamburger-menu::before {
  content: "Close";
  position: absolute;
  font-weight: 600;
  font-size: 1.3rem;
  color: #eee;
  margin-right: 40px;
  top: 9px;
  display: block;
}

@media screen and (max-width: 768px) {
  .active .hamburger-menu:before {
    content: "";
  }
}

.bar {
  width: 1.9rem;
  height: 1.5px;
  border-radius: 2px;
  background-color: #fff;
  transition: 0.5s;
  position: relative;
}

.active .hamburger-menu::before:hover {
  color: white;
  transition: 0.2s;
}

.bar:hover {
  color: white;
  transition: 0.2s;
}

.bar::before,
.bar::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: #fff;
  transition: 0.5s;
}

.bar::before {
  -webkit-transform: translateY(-9px);
          transform: translateY(-9px);
}

.bar::after {
  -webkit-transform: translateY(9px);
          transform: translateY(9px);
}

.active .bar {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent;
}

.active .bar::before {
  -webkit-transform: translateY(0) rotate(45deg);
          transform: translateY(0) rotate(45deg);
}

.active .bar::after {
  -webkit-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
}

.active .bar::before,
.active .bar::after {
  background-color: #eee;
}

/*Overlay Header*/
.site-header-overlay {
  position: absolute;
  z-index: 1042;
  top: 0;
  left: 0;
  /* transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); */
  right: 0;
  height: 100vh;
  /* display: none; */
  pointer-events: none;
}
.active .site-header-overlay {
  /* display: block; */
  pointer-events: all;
}

.overlay-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  transition: -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
}
.active .overlay-bg {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
}

.menufull {
  display: -ms-flexbox;
  width: 100%;
  /* padding-left: 2rem; */
  min-height: 100vh;
  display: flex;
  background-color: #000;
  opacity: 0;
}

/*Overlay header 1st menu */
.menu-1stcol {
  /* -webkit-box-flex: 0; */
  /* -webkit-flex: 0 0 35%;
  -ms-flex: 0 0 35%;
  flex: 0 0 35%; */
  /* width: 35vw; */
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: column;
}

.menu-links {
  position: absolute;
  margin-left: 2rem;
  /* justify-content: center; */
  top: 0;
  z-index: 999;
  display: flex;
  white-space: wrap;
  width: 30%;
  align-items: center;
  margin-top: 160px;
}
@media screen and (max-width: 768px) {
  .menu-links {
    width: 100%;
    margin-left: 0;
    justify-content: center;
  }
}
.menu-links ul {
  list-style-type: none;
  font-weight: 700;
  /* margin: auto; */
}
.menu-links a {
  text-decoration: none;
  color: #fff;
  padding: 0.8rem 0;
  display: inline-block;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: 1px;
  transition: 0.3s;
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-animation: hide 0.5s forwards ease;
          animation: hide 0.5s forwards ease;
}
.menu-links a:hover {
  opacity: 0.7 !important;
}

.active .menu-links a {
  -webkit-animation: appear 0.5s forwards ease var(--i);
          animation: appear 0.5s forwards ease var(--i);
  /* opacity: 1; */
}
@-webkit-keyframes appear {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes appear {
  from {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes hide {
  from {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}
@keyframes hide {
  from {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
  }
}

/* Overlay header 2nd menu */
.menu-2ndcol {
  flex: 0 0 65%;
  width: 65vw;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.active .menufull-media {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition-duration: 1s;
}
.menufull-media {
  background-color: #000;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1), -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    -webkit-transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
}

.menufull-media img {
  max-height: 100vh;
  min-width: 100%;
}
@media screen and (max-height: 450px) {
  .menu-2ndcol,
  .menu-1stcol,
  .menufull {
    min-height: 422px;
  }
  .menufull-media {
    height: 100%;
  }
  .menufull-media img {
    height: 100%;
    max-height: none;
  }
}

.active .menufull {
  opacity: 1;
  transition: 1s;
  height: 100%;
}

/* video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
} */
@media screen and (max-width: 1300px) {
  .container {
    min-width: 0;
  }
}
@media screen and (max-width: 768px) {
  .app,
  .site-header-overlay {
    min-width: 100vh;
  }
  .menu-2ndcol {
    display: none;
  }
  .menufull {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .menu-links li {
    position: relative;
    margin: 20px 0;
  }
  .menu-links li::after {
    content: "";
    position: absolute;
    width: 50px;
    background: #ececec;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }
  .menu-links li:last-child::after {
    display: none;
  }
  .menu-links a {
    font-size: 1.8rem;
  }
  .active .hamburger-menu::before {
    color: black;
    font-weight: 200;
  }
  .active .bar::after,
  .active .bar::before {
    background: white;
  }

  .container {
    min-width: 100%;
  }
  .site-header-overlay {
    min-width: 100%;
  }
}

#contact {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  flex-direction: column;
}

#contact .contact-container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 500px;
}

@media screen and (max-width: 916px) and (min-width: 768px) {
  #contact .contact-container {
    flex-wrap: wrap;
  }
}

#contact .contact-container .contact-img {
  height: 100%;
  width: 100%;
  position: relative;
  transition: 0.6 ease-in-out;
}

@media screen and (max-width: 768px) {
  #contact .contact-container .contact-img {
    display: none;
  }
}

#contact .contact-container .contact-wrapper {
  width: 100%;
  height: 100%;
}

#contact .contact-container .contact-wrapper .section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  color: #eee;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 60px;
  font-weight: 300;
  transition: 0.6s ease-in-out;
}

@media screen and (max-width: 1100px) {
  #contact .contact-container .contact-wrapper .section-header {
    font-size: 40px;
  }
}

#contact .contact-container .contact-wrapper .contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#contact .contact-container .contact-wrapper .contact-form .form-group {
  min-width: 500px;
  margin: 5px 0;
  transition: 0.6s ease-in-out;
}

@media screen and (max-width: 1100px) {
  #contact .contact-container .contact-wrapper .contact-form .form-group {
    min-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  #contact .contact-container .contact-wrapper .contact-form .form-group {
    min-width: 70%;
  }
}

#contact .contact-container .contact-wrapper .contact-form .form-group .form-control {
  width: 100%;
  padding: 5px 10px;
  border: 2px solid #ccc;
  transition: border-color ease-in-out 0.15s box-shadow ease-in-out 0.15s;
  line-height: 1.42857143;
  outline: none;
  color: #fff;
  background: #000;
  letter-spacing: 1px;
}

#contact .contact-container .contact-wrapper .contact-form .form-group .form-control:focus {
  border-color: #bd4b4b;
}

#contact .contact-container .contact-wrapper .contact-form .form-group .form-control input,
#contact .contact-container .contact-wrapper .contact-form .form-group .form-control textarea {
  border: none;
  outline: none;
}

#contact .contact-container .contact-wrapper .contact-form .contact-social {
  margin-top: 30px;
}

#contact .contact-container .contact-wrapper .contact-form .send-btn {
  padding: 5px 10px;
  max-width: 500px;
  max-height: 30px;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  background: #bd4b4b;
  transition: 0.6s ease-in-out;
}

@media screen and (max-width: 1100px) {
  #contact .contact-container .contact-wrapper .contact-form .send-btn {
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  #contact .contact-container .contact-wrapper .contact-form .send-btn {
    max-width: 70%;
    width: 100%;
  }
}

#contact .contact-container .contact-wrapper .contact-form .send-btn .alt-send-button {
  width: 500px;
  transition: 0.6s ease-in-out;
}

@media screen and (max-width: 1100px) {
  #contact .contact-container .contact-wrapper .contact-form .send-btn .alt-send-button {
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  #contact .contact-container .contact-wrapper .contact-form .send-btn .alt-send-button {
    max-width: 100%;
  }
}

#contact .contact-container .contact-wrapper .contact-form .send-btn .send-text {
  display: block;
  margin-top: 13px;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 700;
  color: #eee;
}

#contact .contact-container .contact-wrapper .contact-form .send-btn .paper-plane {
  color: #eee;
  margin-top: 2px;
  font-size: 15px;
}

#contact .contact-container .contact-wrapper .contact-form .send-btn .alt-send-button:hover {
  -webkit-transform: translate3d(0px, -29px, 0px);
          transform: translate3d(0px, -29px, 0px);
}
/*# sourceMappingURL=Contact.css.map */
.resume-container {
  background-color: #eee;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.resume {
  margin-top: 80px;
  margin-bottom: 100px;
}

embed {
  width: 100%;
  height: 800px;
  margin-top: 80px;
}

footer {
  display: flex;
  justify-content: center;
  height: 750px;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  background: #17181c;
  border-top: 1px solid #232a34;
}
@media screen and (max-width: 568px) {
  footer {
    height: auto;
  }
}
footer .video {
  height: 100%;
}
footer video {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  background: #232a34;
}
/* .footer-logo { */
/* margin-right: 150px; */
/* } */
.footer-logo img {
  width: 120px;
}
.footer-logo:hover {
  -webkit-animation: 1s infinite alternate pulse;
          animation: 1s infinite alternate pulse;
  transition: 1s ease-out;
}
@-webkit-keyframes pulse {
  from {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.left-container {
  width: 45%;
  height: 100%;
}
.right-container {
  width: 55%;
  height: 100%;
  padding: 8rem;
  /* padding-left: 50px; */
}

.right-container .about ul {
  list-style-type: none;
}
.right-container .about ul li {
  margin-bottom: 20px;
  position: relative;
}
.right-container .about ul a {
  color: #eee;
  text-decoration: none;
  font-size: 32px;
}

.right-container .about ul li a:before {
  content: "";
  position: absolute;
  left: -30px;
  top: 13px;
  width: 6px;
  height: 4px; /* as the half of the width */
  background-color: #eee;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-top-left-radius: 110px; /* 100px of height + 10px of border */
  border-top-right-radius: 110px; /* 100px of height + 10px of border */
  border: 10px solid;
  border-bottom: 0;
  opacity: 0;
}

.right-container .about ul li:hover a:before {
  opacity: 1;
  transition: 0.6s;
}
.right-container .about ul li:hover a {
  margin-left: 10px;
  transition: 0.6s;
}

.web-detail-container1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  position: relative;
}
.social ul {
  display: flex;
  list-style-type: none;
}
.social ul li {
  margin-right: 50px;
  position: relative;
  font-size: 32px;
  z-index: 2;
}
.social ul li a {
  color: #eee;
}
.social ul li:last-child {
  margin-right: 0;
}
.social ul li:before {
  content: "";
  border: 2px solid;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  width: 30px;
  height: 30px;
  background-color: #bd4b4b;
  opacity: 0;
  outline: none;
  border: none;
}
.social ul li::before {
  top: -8px;
  left: -8px;
}

.social ul li:hover::before {
  opacity: 1;
  transition: 0.3s;
}
.social ul li a:hover {
  color: #ffffff;
}

.wd-container2 .about {
  width: 50%;
}
.wd-container2 .description {
  width: 50%;
}
.wd-container2 p {
  margin: 20px;
}
.blog a {
  text-decoration: none;
}
.gallery,
.blog {
  font-size: 16px;
  letter-spacing: 1.1px;
  line-height: 28px;
}
.gallery span,
.blog a span {
  color: #bd4b4b;
  font-weight: 600;
  padding: 0.1rem 0.2rem;
  background-color: #eee;
}
.gallery span:hover,
.blog a span:hover {
  position: relative;
  bottom: 1.6px;
  transition: 0.6s;
}

.copyright {
  text-align: center;
  color: #eee;
}
.blog,
.gallery {
  text-align: right;
  transition: 0.6s;
  color: #eee;
}
.wd-container2 p {
  margin-right: 0;
}
hr {
  display: none;
}
@media screen and (max-width: 1400px) {
  .right-container {
    padding: 6rem;
    transition: 0.6s;
  }
  .footer-logo img {
    width: 100px;
    transition: 0.6s;
  }
  .social ul li,
  .right-container .about ul a {
    font-size: 28px;
    transition: 0.6s;
  }
}

@media screen and (max-width: 1165px) {
  .right-container {
    padding: 6rem 4rem;
    transition: 0.6s;
  }
  .social ul li {
    margin-right: 30px;
    transition: 0.6s;
  }
}

@media screen and (max-width: 800px) {
  .left-container {
    display: none;
    transition: 0.6s;
  }
  .right-container {
    width: 100%;
    transition: 0.6s;
  }
  .blog,
  .gallery {
    text-align: right;
    transition: 0.6s;
  }
  .wd-container2 p {
    margin: 20px 0 20px 20px;
    transition: 0.6s;
  }
  hr {
    display: block;
    margin: 20px 0;
    transition: 0.6s;
  }
}

@media screen and (max-width: 486px) {
  .web-detail-container1 {
    flex-direction: column;
    margin-bottom: 50px;
    transition: 0.6s;
  }
  .footer-logo {
    margin-bottom: 50px;
    transition: 0.6s;
  }
  .wd-container2 .about {
    text-align: center;
    transition: 0.6s;
  }
  .wd-container2 p {
    text-align: center;
    transition: 0.6s;
  }
  .wd-container2 p {
    margin: 0;
    margin-bottom: 20px;
    transition: 0.6s;
  }
}

.portfolio {
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0;
  position: relative;
}

@media screen and (max-width: 768px) and (min-width: 568px) {
  .portfolio {
    align-items: flex-end;
  }
}

@media screen and (max-width: 634px) {
  .portfolio {
    align-items: center;
  }
}

.portfolio h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) and (min-width: 568px) {
  .portfolio h1 {
    margin-right: 50px;
  }
}

@media screen and (max-width: 634px) {
  .portfolio h1 {
    margin-right: 0;
  }
}

.portfolio .projectsList {
  margin: 10px 0 50px 0;
  white-space: nowrap;
  padding: 0 10px;
  display: flex;
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(1.8rem * calc(1 - var(--tw-space-x-reverse)));
}

@media screen and (max-width: 768px) and (min-width: 569px) {
  .portfolio .projectsList {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
    left: 50px;
    top: 220px;
    display: block;
    z-index: 0;
    margin: 0;
    padding: 0;
  }
  .portfolio .projectsList:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background: #a8a7a7;
    top: 0;
    right: -36px;
  }
}

@media screen and (max-width: 634px) {
  .portfolio .projectsList {
    justify-content: center;
    display: none;
  }
}

.portfolio .projectsList h4 {
  font-size: 1rem;
  line-height: 2rem;
  margin-right: 45px;
  padding: 2px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.1s all;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  font-weight: 400;
}

.portfolio .projectsList h4.active {
  background: black;
  color: white;
}

@media screen and (max-width: 768px) and (min-width: 568px) {
  .portfolio .projectsList h4 {
    margin: 20px 0;
  }
}

.portfolio .portfolio-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) and (min-width: 634px) {
  .portfolio .portfolio-container {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 634px) {
  .portfolio .portfolio-container {
    justify-content: center;
  }
}

.portfolio .portfolio-container .portfolio-item {
  width: 560px;
  height: 320px;
  margin: 10px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  border-radius: 20px;
  border: 1px solid #f0efef;
  position: relative;
  transition: all 0.5s ease-out;
  cursor: pointer;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .portfolio .portfolio-container .portfolio-item {
    width: 400px;
    height: 185px;
  }
}

.portfolio .portfolio-container .portfolio-item h3 {
  position: absolute;
  font-size: 20px;
}

.portfolio .portfolio-container .portfolio-item img {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 20px;
}

.portfolio .portfolio-container .portfolio-item:hover {
  background: #17181c;
}

.portfolio .portfolio-container .portfolio-item:hover img {
  opacity: 0.2;
  z-index: 0;
}

.portfolio .sm-projectsList {
  display: none;
}

@media screen and (max-width: 634px) {
  .portfolio .sm-projectsList {
    display: flex;
    flex-direction: column;
    margin: 10px 0 40px 0;
  }
}

.portfolio .sm-projectsList .drop-box {
  position: relative;
}

.portfolio .sm-projectsList .drop-box select {
  padding: 7px 20px;
  width: 180px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  color: #eee;
  background: #17181c;
  -webkit-appearance: none;
          appearance: none;
  outline: none;
}

.portfolio .sm-projectsList .drop-box .down-icon,
.portfolio .sm-projectsList .drop-box .up-icon {
  position: absolute;
  right: 10px;
  color: white;
  top: 11px;
  pointer-events: none;
  transition: all 0.3s;
}

.portfolio .sm-projectsList .drop-box .up-icon {
  top: 11px;
}
/*# sourceMappingURL=Portfolio.css.map */
.ityped-cursor {
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.qualification {
  height: 30px;
}

.my-name {
  color: #f4f9f9;
  font-size: 64px;
  text-align: center;
  margin-bottom: 24px;
  /* animation: slideIn 1s; */
}
@media screen and (max-width: 768px) {
  .my-name {
    font-size: 50px;
  }
}

@media screen and (max-width: 480px) {
  .my-name {
    font-size: 40px;
  }
}

/* @keyframes slideIn {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0);
  }
} */

.react-reveal {
  z-index: 999;
}

#hero {
  height: 100vh;
}

.main-container {
  overflow-x: hidden;
  height: 100vh;
}

.timeline-section {
  background: #eee;
  min-height: 100vh;
  padding: 100px 15px;
}
.timeline-heading h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 50px;
}

@media screen and (max-width: 480px) {
  .timeline-heading h1 {
    font-size: 2rem;
  }
}
.timeline-section .timeline-items {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.timeline-items::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #2f363e;
  left: calc(50% - 1px);
}

.timeline-item {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 30px);
  text-align: center;
}

.timeline-item:nth-child(even) {
  padding-left: calc(50% + 30px);
}

.timeline-dot {
  height: 16px;
  width: 16px;
  background-color: #bd4b4b;
  position: absolute;
  left: calc(50% - 8px);
  border-radius: 50%;
  top: 10px;
}

.timeline-date {
  font-size: 18px;
  color: #bd4b4b;
  margin: 6px 0 15px;
  text-align: center;
}

.timeline-content {
  background-color: #2f363e;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
}
.timeline-content h3 {
  font-size: 20px;
  color: #fff;
  margin: 0 0 10px;
  text-transform: capitalize;
  font-weight: 500;
}
.timeline-content p {
  color: #c8c8c8;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

/* responsive */
@media (max-width: 767px) {
  .timeline-items::before {
    left: 7px;
  }
  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 37px;
  }
  .timeline-dot {
    left: 0;
  }
}

